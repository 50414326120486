import { RefObject } from 'react';
import { extname } from 'path';
import { RuleSet } from 'styled-components';
import urlJoin from 'url-join';

type ScrollToOptions = {
  adittionalScroll?: number;
  anchor?: 'bottom' | 'top';
};

export type PropsFromCss<T> = T extends RuleSet<infer K> ? K : never;

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const toCamel = (string: string) => {
  return string
    ? string.toLowerCase().replace(/([-_][a-z])/gi, $1 => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
      })
    : '';
};

export const getPathnameFromLocation = (location: { pathname: string; host: string }) => {
  if (location.host === 'gatsby-staging.ector.io') {
    return location.pathname.split('/').slice(2).join('/');
  }

  return location.pathname;
};

export const interpolate = (stringToInterpolate: string, values: any) => {
  return stringToInterpolate
    ? stringToInterpolate.replace(/\${(.*?)}/g, str => {
        const matches = str.match(/\${(.*?)}/);

        return (matches && (values?.[matches[1]] || matches[1])) || str;
      })
    : stringToInterpolate;
};

export const formatPriceToParts = (
  value: string | number | null,
  locale: string,
  options?: Intl.NumberFormatOptions,
): Intl.NumberFormatPart[] => {
  const price = typeof value === 'string' ? parseFloat(value) : value || 0;
  const minimumFractionDigits = !Number.isInteger(price) ? 2 : 0;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits,
    ...options,
  });

  return formatter.formatToParts(price);
};

export const getCookie = (field: string) => {
  const regex = new RegExp(`(?:(?:^|.*;\\s*)${field}\\s*=\\s*([^;]*).*$)|^.*$`);

  return typeof window !== 'undefined' ? document.cookie.replace(regex, '$1') : '';
};

export const addTrailingSlash = (path: string): string => (path.endsWith('/') ? path : `${path}/`);

export const formatUrl = (...parts: string[]): string => addTrailingSlash(urlJoin(...parts));

export const getFileExtension = (name: string, path?: string): string => {
  const extension = extname(name) || (path && extname(path)) || '';

  return extension.replace('.', '').toLowerCase();
};

export const scrollToRef = (Ref: RefObject<HTMLDivElement>, options?: ScrollToOptions) => {
  const anchor = options?.anchor || 'bottom';

  if (Ref.current) {
    const currentScrollTop = Math.max(
      window.pageYOffset,
      document.querySelector('body')?.scrollTop || 0,
    );
    const scrollTo =
      anchor === 'bottom'
        ? Ref.current?.getBoundingClientRect().bottom +
          currentScrollTop -
          window.innerHeight +
          (options?.adittionalScroll || 0)
        : Ref.current?.getBoundingClientRect().top +
          currentScrollTop -
          (options?.adittionalScroll || 0);

    if (
      scrollTo > 0 &&
      ((anchor === 'bottom' && scrollTo > currentScrollTop) ||
        (anchor === 'top' && scrollTo < currentScrollTop))
    ) {
      document.querySelector('body')?.scrollTo(0, scrollTo);
      window.scrollTo(0, scrollTo);
    }
  }
};

export const debounce = (func: (...args: any[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

import 'firebase/auth';
import { useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { FirebaseError } from 'firebase/app';
import {
  AuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { EctorErrorType } from 'hooks/graphql-hooks';
import { removeItem } from 'utils/local-storage-helpers';
import { useLazyApi } from './use-api';

export enum PublicIdentityProvider {
  apple = 'apple',
  google = 'google',
}

type PrivateIdentityProvider = {
  ssoId: string;
  ssoProtocol: SsoProtocolType;
};

const ssoProtocolTypes = ['saml', 'oauth'] as const;

export const stringToSsoProtocolType = (ssoProtocolType: string): SsoProtocolType | undefined => {
  if ((ssoProtocolTypes as readonly string[]).includes(ssoProtocolType)) {
    return ssoProtocolType as SsoProtocolType;
  }

  return undefined;
};
type SsoProtocolType = (typeof ssoProtocolTypes)[number];

export enum SsoStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  NO_PREV_REDIRECT = 'NO_PREV_REDIRECT',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
}

export const useSSO = ({
  handleLoginCompletion,
  isWebView = false,
}: {
  handleLoginCompletion: (token: string, refreshToken: string) => void;
  isWebView?: boolean;
}) => {
  const [status, setStatus] = useState<SsoStatus>(SsoStatus.NOT_CHECKED);
  const [errors, setErrors] = useState<EctorErrorType[]>([]);

  async function signInWithPopupUsingProvider(provider: AuthProvider) {
    const auth = getAuth();
    try {
      return await signInWithPopup(auth, provider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/cancelled-popup-request':
          case 'auth/popup-closed-by-user':
            break;
          default:
            datadogRum.addError(error);
            throw error;
        }
      } else {
        datadogRum.addError(error);
        throw error;
      }
    }
  }

  function getIdentityProvider({
    publicIdentityProvider,
    privateIdentityProvider,
  }: {
    publicIdentityProvider?: PublicIdentityProvider;
    privateIdentityProvider?: PrivateIdentityProvider;
  }) {
    if (publicIdentityProvider) {
      switch (publicIdentityProvider) {
        case PublicIdentityProvider.google:
          return new GoogleAuthProvider();
        case PublicIdentityProvider.apple:
          return new OAuthProvider('apple.com');
        default:
          break;
      }
    } else if (privateIdentityProvider) {
      switch (privateIdentityProvider.ssoProtocol) {
        case 'saml':
          return new SAMLAuthProvider(privateIdentityProvider.ssoId);
        case 'oauth':
          return new OAuthProvider(privateIdentityProvider.ssoId);
        default:
          break;
      }
    }
    throw new Error('Identity provider not found');
  }

  const { fetch: swapToken, isFetching: swapTokenLoading } = useLazyApi(
    'swapToken',
    ({ token, refreshToken }) => {
      resetLoginState();
      handleLoginCompletion(token, refreshToken);
    },
    async error => {
      await logout();
      setErrors([error]);
    },
  );

  async function logout() {
    resetLoginState();
    const auth = getAuth();
    await signOut(auth);
  }

  function resetLoginState() {
    setStatus(SsoStatus.NOT_CHECKED);
    removeItem('checkedEmail');
  }

  async function signInWithPrivateIdentityProvider(ssoId: string, ssoProtocol: SsoProtocolType) {
    await signInWithIdentityProvider(
      getIdentityProvider({ privateIdentityProvider: { ssoId, ssoProtocol } }),
    );
  }

  async function signInWithPublicIdentityProvider(idp: PublicIdentityProvider): Promise<void> {
    try {
      await signInWithIdentityProvider(getIdentityProvider({ publicIdentityProvider: idp }));
    } catch (e: any) {
      setErrors([
        {
          code: 'USER_CANCELLED',
          message: 'User cancelled connexion',
        },
      ]);
    }
  }

  async function signInWithIdentityProvider(idp: AuthProvider): Promise<void> {
    const userCredentials = await signInWithPopupUsingProvider(idp);

    if (userCredentials) {
      const ssoToken = await userCredentials.user.getIdToken();

      // if it's react native app client
      if (isWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(ssoToken);
      } else {
        await swapToken({ sso_token: ssoToken, origin: 'front' });
      }
    }
  }

  const { fetch: checkEmail, isFetching: checkEmailLoading } = useLazyApi(
    'checkEmail',
    async ({ found, idSSO, standardSSO }) => {
      if (found && idSSO && standardSSO) {
        const ssoProtocolType = stringToSsoProtocolType(standardSSO);

        if (!ssoProtocolType) {
          setErrors([{ code: 'invalidSsoStandard' }]);

          return;
        }
        setStatus(SsoStatus.AVAILABLE);
        await signInWithPrivateIdentityProvider(idSSO, ssoProtocolType);
      } else {
        setStatus(SsoStatus.NOT_AVAILABLE);
      }
    },
    () => {},
  );

  return {
    checkEmail,
    loading: checkEmailLoading || swapTokenLoading,
    status,
    setStatus,
    signInWithPrivateIdentityProvider,
    signInWithPublicIdentityProvider,
    errors,
    logout,
  };
};
